/*======================================
  General Style Start
======================================*/
:root {
  --font-prompt: "Prompt", sans-serif;
  --font-poppins: "Poppins", sans-serif;
  --color-white: #fff;
  --color-black: #06000a;
  --color-blue: #6366f1;
  --color-green: #2dbc06;
  --color-blue-dim: #6366f126;
}

body {
  font-family: var(--font-prompt);
  background-color: var(--color-black);
  overflow-x: hidden;
}

.btn {
  padding: 10px 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  border-radius: 6px;
  color: var(--color-white);
  border: 2px solid var(--color-blue);
}

.btn.btn-solid {
  background-color: var(--color-blue);
}

.btn.btn-regular {
  background-color: transparent;
}

.btn:hover,
.btn:focus {
  color: var(--color-white);
  border-color: var(--color-blue);
  background-color: var(--color-blue);
}

.btn.btn-solid:hover,
.btn.btn-solid:focus {
  background-color: transparent;
}

.btn.btn-regular:active {
  color: var(--color-white);
  border-color: var(--color-blue);
  background-color: var(--color-blue);
}

.btn.btn-solid:active {
  color: var(--color-white);
  border-color: var(--color-blue);
  background-color: transparent;
}

.btn-close {
  background: none;
}

.form-control {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding: 15px 25px;
  margin: auto 0;
  border-radius: 9px;
  border: 1px solid var(--color-white);
  background-color: transparent;
}

.form-control:focus {
  color: var(--color-white);
  background-color: transparent;
  border-color: var(--color-white);
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder{
  color: rgb(255, 255, 255, 0.60);
}

.color-blue {
  color: var(--color-blue);
}

.down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-light {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.prompt-medium {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.prompt-semibold {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-light-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.prompt-regular-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.prompt-medium-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.prompt-semibold-italic {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.dashboard-hero .modal {
  --bs-modal-width: 600px;
}

.modal .modal-dialog .modal-content {
  z-index: 1;
  border-radius: 34px;
  border: 3px solid var(--color-white);
  background-color: var(--color-black);
  box-shadow: 0px 0px 16px 0px var(--color-blue);
}

.modal-content .modal-header {
  padding: 25px 30px 0;
  border: 0;
}

.modal-content .modal-header .btn-close {
  padding: 0;
  opacity: 1;
  width: 25px;
  height: 25px;
}

.modal .modal-body {
  padding: 0 64px;
}

.modal .modal-body h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.5;
}

.modal .sub-heading {
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 1em;
}

.modal .modal-body-end h4 {
  font-size: 25px;
  /* font-style: italic; */
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: var(--color-white);
}

.modal .modal-body-end h4 strong {
  font-size: 25px;
  font-weight: 600;
  color: var(--color-blue);
}

.modal .modal-body-end label {
  font-size: 16px;
  font-weight: 300;
  line-height: 38.12px;
  text-align: left;
  color: var(--color-white);
  margin-bottom: 12px;
}

.modal .modal-body-end label strong {
  font-weight: 400;
}

.modal .modal-body-end .input-group{
 margin-bottom: 34px;
}

.modal .modal-body-end .input-group .form-control {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  color: var(--color-white);
  border: 0;
  border-radius: 9px;
  background: var(--color-blue-dim);
}

.modal .modal-body-end .input-group .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  color: var(--color-white);
}

.modal .modal-body-end .input-group .btn {
  border: 0;
  margin: 0 !important;
  border-radius: 9px;
  background: var(--color-blue-dim);
}

.modal .modal-body-end .input-group .btn:hover {
  background-color: #6366f140;
}

.modal .toast-body {
  color: var(--color-white);
  background-color: rgba(6 0 10 / 0.75);
}

.modal .modal-footer {
  margin-top: 20px;
  border: 0;
  padding: 0 40px 20px;
  justify-content: center;
}

.modal .modal-footer .modal-footer-inner {
  margin: 0;
  padding: 20px 20px 0px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
  border-top: 1px solid #ffffff66;
}

.modal .modal-footer .modal-footer-inner strong {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--color-blue);
}

.modal .modal-body-end {
  padding: 25px 0;
}

/****** custom checkboxes ********/
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  color: var(--color-white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
}
.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--color-blue);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*********************/

/* src/CustomSelect.css */
.custom-select-container {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.custom-select-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-transform: uppercase;
  padding: 15px 25px;
  gap: 0.625rem;
  margin: auto 0;
  border-radius: 9px;
  border: 1px solid var(--color-white);
}

.custom-select-header:hover {
  border-color: rgba(255 255 255 / 0.75);
}

.custom-select-header--open {
  border-radius: 0.5rem 0.5rem 0 0;
  border-width: 1px 1px 0 1px;
  border-color: rgba(255 255 255 / 0.75);
}

.custom-select-header-text {
  gap: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0);
}

.custom-select-arrow.custom-select-arrow--open {
  transform: rotate(180deg);
}

.custom-select-arrow svg {
  width: 20px;
  height: 20px;
  color: rgb(255 255 255 / 0.75);
}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  border: 1px solid rgba(255 255 255 / 0.75);
  background-color: rgba(6 0 10 / 0.95);
  z-index: 1;
  list-style-type: none;
  padding: 0;
  z-index: 3;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-select-options::-webkit-scrollbar {
  display: none;
}

.custom-select-option {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-white);
  padding: 0.5rem 0.5rem;
  gap: 0.625rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-transform: uppercase;
}

/*======================================
  General Style End
======================================*/
/*!============================================================================*/
/*======================================
  Navbar Style Start
======================================*/
.navbar {
  padding: 20px 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 4;
}

.navbar .navbar-brand {
  padding: 0;
  width: auto;
  max-height: 40px;
  margin-right: 1.5rem;
}

.navbar .navbar-brand .img-fluid {
  height: 40px;
}

.navbar .navbar-nav {
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.navbar .navbar-nav .nav-item .nav-link {
  gap: 0.25rem;
  display: flex;
  align-items: center;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  --bs-nav-link-color: var(--color-white);
  --bs-nav-link-hover-color: var(--color-blue);
}

.nav-item-btn .img-fluid {
  width: 1.5rem;
  height: 1.5rem;
  max-width: 100%;
}

.navbar-toggler {
  color: var(--color-blue);
  border-color: rgba(99 102 241 / 0.75);
}

.navbar-toggler span {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(99, 102, 241, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar .login-button, .navbar .navbar-dropdowns {
  margin-left: 1.5rem;
}

/*======================================
  Navbar Style End
======================================*/
/*======================================
  Navbar Dropdowns Start
======================================*/

.user-dropdown {
  margin-left: 30px;
}
.user-dropdown #user-button {
  background: transparent;
  border: none;
  padding: 0;
}
.user-dropdown .user-img {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
}
.user-dropdown .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-dropdown .user-data {
  margin-right: 30px;
  max-width: 88px;
  overflow: hidden;
}
.user-dropdown .user-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.user-dropdown .user-role {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  opacity: 0.44;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.user-dropdown .dropdown-menu {
  border: 1.5px solid #6366F1;
  background-color: #06000A;
  color: var(--color-white);
  overflow: hidden;
  width: 100%;
  max-width: 179px;
  border-radius: 10px;
  transform: translateX(-50%) !important;
  -webkit-transform: translateX(-50%) !important;
  -ms-transform: translateX(-50%) !important;
  -moz-transform: translateX(-50%) !important;
  -o-transform: translateX(-50%) !important;
  left: 50% !important;
  top: calc(100% + 20px) !important;
}
.user-dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-white);
}
.user-dropdown .dropdown-menu hr.dropdown-divider {
  border: 1px solid rgb(255 255 255 / 8%);
}
.user-dropdown .menu-user-data {
  padding: 0px 12px;
}
.user-dropdown .dropdown-item:focus, .user-dropdown .dropdown-item:hover, .user-dropdown .dropdown-item:active {
  background-color: transparent;
  color: var(--color-blue);
}

/**/
.notifications-dropdown #noti-button {
  background: transparent;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.notifications-dropdown #noti-button .red-dot {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  right: 0px;
  background: #FF0000;
  border-radius: 50%;
}
.notifications-dropdown #noti-button::after {
  display: none;
}
.notifications-dropdown #noti-button svg {
  width: 100%;
  height: 100%;
}
.notifications-dropdown .dropdown-menu {
  border: 1.5px solid #6366F1;
  background-color: #06000A;
  color: var(--color-white);
  overflow: hidden;
  border-radius: 10px;
  left: auto !important;
  top: 100% !important;
  right: 0 !important;
  padding: 10px 22px;
  min-width: 317px;
}
.notifications-dropdown .dropdown-menu .dropdown-item {
  white-space: normal;
  color: var(--color-white);
  margin: 12px 0px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  -webkit-line-clamp: 2;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 0px 0px 30px;
}
.notifications-dropdown .dropdown-menu hr {
  border-bottom: 1px solid rgb(255 255 255 / 16%);
}
.notifications-dropdown .dropdown-menu hr:last-of-type {
  display: none;
}
.notifications-dropdown .dropdown-menu .dropdown-item:last-of-type {
  border-bottom: none;
}
.notifications-dropdown .dropdown-menu .dropdown-item::before{
  content: "";
  position: absolute;
  background: #6366F1;
  width:8px;
  height: 8px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
  left: 5px;
}
.notifications-dropdown .dropdown-item:focus, .notifications-dropdown .dropdown-item:hover, .notifications-dropdown .dropdown-item:active {
  background-color: transparent;
  color: var(--color-blue);
}


/*======================================
  Navbar Dropdowns End
======================================*/
/*!============================================================================*/
/*======================================
  Home Page Style Start
======================================*/
.home-hero .title {
  font-size: 70px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
}

.home-hero .sub-title {
  font-family: Prompt;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.02px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
}

.analytics {
  z-index: 1;
  position: relative;
  padding-top: 140px;
}

.analytics .powered-by {
  margin-bottom: 160px;
}

.benefits .title,
.analytics .title {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  color: var(--color-white);
  text-transform: uppercase;
}

.analytics .powered-by .boxes {
  gap: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.analytics .powered-by .box {
  gap: 16px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
  text-transform: capitalize;
  max-width: 242px;
  width: 100%;
  height: 145px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.analytics .analytics-inner {
  z-index: 1;
  position: relative;
  padding-bottom: 90px;
}

.analytics .lg-boxes {
  gap: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.analytics .lg-box {
  width: 100%;
  display: flex;
  max-width: 506px;
  padding: 20px 40px;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-black);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.analytics .description {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: var(--color-white);
  margin-bottom: 27px;
}

.analytics .description span {
  color: var(--color-blue);
}

.lg-box-logs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 20px;
  border-radius: 6px;
  background-color: var(--color-blue-dim);
}

.lg-box-logs .log {
  padding: 15px 0;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.lg-box-logs .log:last-child {
  border-bottom: 0;
}

.lg-box-logs .log .log-index {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  flex: 0 0 10%;
  max-width: 10%;
  color: var(--color-white);
}

.lg-box-logs .log .log-details {
  max-width: 50%;
  flex: 0 0 50%;
}

.lg-box-logs .log .log-details .chain {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: var(--color-white);
  display: block;
  margin-bottom: 1em;
}

.lg-box-logs .log .log-details .pair {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: rgba(255 255 255 / 0.5);
  display: block;
}

.lg-box-logs .log .log-time {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: right;
}

.lg-box-logs .log .log-time .time {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: right;
  color: var(--color-blue);
  display: block;
  margin-bottom: 1em;
}

.lg-box-logs .log .log-time .percentage {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-green);
  display: block;
  text-align: right;
}

.investment {
  z-index: 1;
  padding-top: 90px;
  position: relative;
  padding-bottom: 100px;
}

.investment-box {
  padding: 60px;
  border-radius: 10px;
  border: 2px solid #ffffff80;
  background-color: var(--color-black);
}

.investment-box .investment-box-inner {
  font-size: 30px;
  font-weight: 300;
  line-height: 58.83px;
  text-align: left;
  color: var(--color-white);
  max-width: 655px;
  margin-left: auto;
  margin-right: auto;
}

.investment-box .investment-box-inner strong {
  font-weight: 600;
  color: var(--color-green);
}

.investment-box .investment-box-inner > div {
  display: block;
}

.investment-box .custom-select-container {
  margin: 0;
  height: 47px;
  max-width: 150px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 145px;
}

.investment-box .custom-select-container .custom-select-header {
  height: 100%;
  padding: 10px 15px;
  color: var(--color-green);
  border-color: var(--color-green);
}

.investment-box .custom-select-container .custom-select-arrow svg path {
  fill: var(--color-green);
}

.investment-box .custom-select-container .custom-select-header .custom-select-header-text {
  line-height: 1;
}

.benefits {
  z-index: 1;
  position: relative;
  padding-top: 90px;
  padding-bottom: 200px;
}

.benefits .title {
  margin-bottom: 1em;
}

.benefits .description {
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
  max-width: 725px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 85px;
}

.benefits-boxes {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
}

.benefits-boxes .benefits-box {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
}

.benefits-boxes .benefits-box .box-title {
  width: 107px;
  height: 107px;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 700;
  line-height: 41.54px;
  text-align: center;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  backface-visibility: hidden;
  background-image: url("./images/ring.png");
}

.benefits-boxes .benefits-box:nth-child(2) .box-title::after,
.benefits-boxes .benefits-box:nth-child(2) .box-title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  max-width: 240px;
  background-color: rgba(255 255 255 / 0.5);
}

.benefits-boxes .benefits-box:nth-child(2) .box-title::before {
  left: 0;
  transform: translateX(-50%);
}

.benefits-boxes .benefits-box:nth-child(2) .box-title::after {
  right: 0;
  transform: translateX(50%);
}

.benefits-boxes .benefits-box .box-desc {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 44px;
}

.benefits-boxes .benefits-box .box-desc .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--color-blue);
  margin-bottom: 1em;
  max-width: 100%;
}

.benefits-boxes .benefits-box .box-desc .description {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  line-height: 23.11px;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 0;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

.login {
  z-index: 1;
  padding-top: 200px;
  position: relative;
  padding-bottom: 200px;
}

.login .title {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 11px;
  color: var(--color-white);
}

.login .title span {
  color: var(--color-blue);
}

.login .description {
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 2.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.login .btn {
  max-width: 250px;
}

.home-hero,
.dashboard-hero,
.withdrawal,
.withdrawal-notification {
  z-index: 1;
  padding-top: 200px;
  position: relative;
  padding-bottom: 90px;
}

.dashboard-hero {
  z-index: 3;
}

.star {
  position: absolute;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: -0.04em;
  text-align: center;
  z-index: 1;
  color: var(--color-white);
}

.star:first-child {
  left: 10%;
  top: 0%;
}

.star:nth-child(2) {
  left: 30%;
  top: 67%;
}

.star:last-of-type {
  right: 0;
  top: 5%;
}

.login::after,
.login::before,
.benefits::after,
.withdrawal::before,
.withdrawal::after,
.withdrawal-notification::before,
.withdrawal-notification::after,
.home-hero::after,
.home-hero::before,
.portfolio::before,
.dashboard-hero::after,
.dashboard-hero::before,
.analytics-inner::before,
.modal .modal-dialog .modal-content::after,
.modal .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 500px;
  filter: blur(250px);
  background-color: var(--color-blue);
}

.modal .modal-dialog .modal-content::after,
.modal .modal-dialog .modal-content::before {
  width: 350px;
  height: 350px;
}

.modal .modal-dialog .modal-content::after {
  right: 0;
  bottom: 0;
}

.modal .modal-dialog .modal-content::before {
  left: 0;
  top: 0;
}

.login::before,
.home-hero::before,
.dashboard-hero::before,
.withdrawal::before,
.withdrawal-notification::before {
  top: 0;
  left: -10%;
}

.home-hero::after,
.dashboard-hero::after,
.withdrawal::after,
.withdrawal-notification::after {
  right: 0;
  top: 100%;
  transform: translateY(-50%);
}

.login::after,
.portfolio::before,
.analytics-inner::before {
  left: 0;
  top: 50%;
  transform: translateX(-50%);
}

.benefits::after {
  top: 0;
  right: 0;
}

.dashboard-hero .title {
  font-size: 50px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  margin-bottom: 1.25em;
}

.dashboard-hero .title .icon {
  width: 50px;
  height: 57px;
}

.dashboard-buttons {
  gap: 44px;
}

.dashboard-buttons .btn {
  gap: 10px;
  min-width: 245px;
  justify-content: space-between;
}

.dashboard-buttons .despoit-button {
  min-width: auto;
}


.dashboard-buttons .btn .btn-title {
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 1.5;
  display: block;
}

.dashboard-buttons .btn .btn-content {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  color: var(--color-blue);
}

.dashboard-buttons .btn:hover .btn-content,
.dashboard-buttons .btn:focus .btn-content {
  color: var(--color-white);
}

.dashboard-buttons .btn-solid {
  width: 268px;
  line-height: 2;
}

.portfolio {
  z-index: 1;
  padding-top: 90px;
  position: relative;
  padding-bottom: 90px;
}

.portfolio .heading {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.portfolio .description {
  max-width: 726px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 28px;
  color: var(--color-white);
}

.portfolio .custom-select-container {
  max-width: 390px;
}

.portfolio .address-group {
  max-width: 676px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio .address-group label,
.portfolio .portfolio-balance label {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  display: block;
  color: rgba(255, 255, 255, 0.6);
}

.portfolio .address-group .input-group .form-control {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  color: var(--color-white);
  border: 0;
  border-radius: 9px;
  height: auto;
  background: var(--color-blue-dim);
}

.portfolio .address-group .input-group .form-control::placeholder {
  font-size: 22px;
  font-weight: 400;
  line-height: 27.2px;
  color: var(--color-white);
}

.portfolio .address-group .input-group .btn {
  border: 0;
  margin: 0 !important;
  border-radius: 9px;
  background: var(--color-blue-dim);
}

.portfolio .address-group .input-group .btn:hover {
  background-color: #6366f140;
}

.portfolio .trading-started {
  max-width: 674px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio .trading-started .trading-started-inner {
  padding: 25px 60px;
  border-radius: 9px;
  margin-top: 27px;
  margin-bottom: 12px;
  border: 1px solid var(--color-blue);
  background-color: rgba(6, 0, 10, 0.85);
  filter: drop-shadow(0 0 20px rgba(6, 0, 10, 0.75));
}

.portfolio .trading-started h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 1em;
  color: var(--color-white);
}

.portfolio .trading-started .btn {
  margin-left: auto;
  margin-right: auto;
  border-color: var(--color-green);
  background-color: var(--color-green);
}

.portfolio .trading-started .note {
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-white);
}

.portfolio .portfolio-balance {
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio .portfolio-balance > .d-flex {
  gap: 61px;
  justify-content: center;
}

.portfolio .portfolio-balance .box {
  min-width: 211px;
  padding: 15px 30px;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-blue);
}

.portfolio .portfolio-balance .box .coin,
.portfolio .portfolio-balance .box .balance {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--color-white);
}

.portfolio .portfolio-balance .box .coin {
  font-weight: 500;
  color: var(--color-blue);
}

/*======================================
  Withdrawal Style Start
======================================*/

.withdrawal{
  padding-bottom: 150px;
}
.withdrawal .heading {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
}
.withdrawal form {
  max-width: 743px;
  margin: 0 auto;
}
.withdrawal form .custom-select-container {
  margin-bottom: 40px;
  max-width: 743px;
}
.withdrawal form .custom-select-header {
  background: rgb(99 102 241 / 16%);
  text-transform: capitalize;
  padding: 26px 25px;
}
.withdrawal form .form-control {
  color: var(--color-white);
  background-color: rgb(99 102 241 / 16%);
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 24px;
  padding: 26px 25px;
}
.withdrawal form .form-control:focus {
  background-color: rgb(99 102 241 / 16%);
}
.withdrawal form .number-input {
  padding: 26px 145px 26px 25px;
}
.withdrawal form span {
  color: var(--color-white);
  font-size: 20px;
  line-height: 24px;
}
.withdrawal form .number-span {
  color: var(--color-blue);
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.withdrawal .availabe-amount{
  margin-bottom: 40px;
}
.withdrawal .checkboxes {
  margin-bottom: 100px;
}
.withdrawal .checkbox-container:first-of-type {
  margin-bottom: 30px;
}
.withdrawal .withdraw-amount {
  border-radius: 10px;
  max-width: 579px;
  margin-inline: auto;
  border: 1.5px solid var(--color-blue);
  padding: 20px 30px;
}
.withdrawal .withdraw-amount button{
  margin-left: 20px;
}
.withdrawal .withdraw-amount .withdraw-data{
   width: calc(100% - 174px);
}
.withdrawal .text-grey{
  color: rgba(255, 255, 255, 0.60);
}
.withdrawal::after {
  bottom: 0;
  top: auto;
  transform: none;
}
.withdrawal form .withdraw-data span {
  display: block;
  margin: 0 0 20px;
}
.withdrawal form .withdraw-data span:last-of-type{
  margin: 0;
}
.withdrawal form .withdraw-data span:nth-child(02){
  font-size: 30px;
  line-height: 34px;
}
.withdrawal form .availabe-amount .custom-select-container {
  max-width: none;
  margin: 0;
}
.withdrawal form .availabe-amount .custom-select-header {
  padding: 0;
  border: none;
  background: transparent;
}

/*======================================
  Withdrawal Style End
======================================*/

/*======================================
  Withdrawal-notification Style End
======================================*/

.withdrawal-notification{
  padding-bottom: 50px;
  padding-top: 140px;
}
.withdrawal-notification::after {
  bottom: 0;
  top: auto;
  transform: none;
}
.withdrawal-notification .heading {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 20px;
  max-width: 100%;
  margin-inline: auto;
}
.withdrawal-notification p {
  max-width: 804px;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  margin-bottom: 60px;
}
.withdrawal-notification .verification-data {
  width: 33.33%;
  margin: 0 0 30px;
}
.withdrawal-notification .verification-data:last-of-type {
  width: 100%;
  margin: 0;
}
.withdrawal-notification .verification-data .small-text{
  font-size: 20px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.60);
}
.withdrawal-notification .verification-data .answer-text{
  font-size: 22px;
  line-height: 1.5;
  color: var(--color-white);
  margin-left: 12px;
}
.withdrawal-notification .verification-content{
  margin: 0 0 32px;
}
.withdrawal-notification .number-tag {
  color: #37FF00;
  font-size: 50px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
  margin: 0 0 30px;
}

/**/
.withdrawal-notification .modals .modal-button {
  width: 100%;
  max-width: 801px;
  margin: 0 auto 50px;
  background: transparent;
  font-size: 24px;
  line-height: 1.5;
  padding: 25px;
  border-radius: 9px;
}
.withdrawal-notification .modals .modal-button div {
  width: 100%;
  height: 100%;
}
.withdrawal-notification .arrow.text-green{
  color: #37FF00;
}
.withdrawal-notification .modals .modal-button.active {
  border-color: #37FF00;
}
.withdrawal-notification .withdraw-process {
  color: #37FF00;
  text-align: center;
  font-size: 50px;
  line-height: 1.5;
  text-transform: uppercase;
  padding-top: 70px;
}
.withdrawal-notification .withdraw-process.red {
  color: #FF0000;
}
.withdrawal-notification .withdraw-process .circle-tick {
  width: 130px;
  height: 130px;
  margin: 0 auto 50px;
}
.withdrawal-notification .withdraw-process .circle-tick svg{
  width: 100%;
  height: 100%;
}
.withdrawal-notification .withdraw-process span {
  margin: 0 0 40px;
  display: block;
}

/*authenticatoion modal*/
.modal-backdrop.show {
  opacity: 0.8;
}
.authentication-modal .modal-content {
  color: #fff;
  padding: 30px 30px;
}
.authentication-modal .modal-dialog {
  width: 100%;
  max-width: 640px;
}
.authentication-modal .modal-content .modal-header{
  padding: 10px;
}
.authentication-modal .modal-content .modal-body{
  padding: 20px 0 0;
}
.authentication-modal .modal-content .modal-footer{
  padding: 0;
}
.authentication-modal .modal-content .text-blue{
  color: var(--color-blue);
}
.authentication-modal .btn-close{
  background-image: url("../Assets/images/cross.svg");
  width: 30px;
  height: 30px;
}
.authentication-modal .modal-body h1 {
  font-size: 40px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}
.authentication-modal .modal-body p {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  margin: 0 0 60px;
}
.authentication-modal.add-modal .modal-body p {
  margin: 0 0 40px;
}
.authentication-modal .modal-footer button {
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  padding: 19px 29px;
}
.authentication-modal form .form-control {
  color: var(--color-white);
  background-color: rgb(99 102 241 / 16%);
  margin-bottom: 34px;
  font-size: 20px;
  line-height: 24px;
  padding: 26px 25px;
}
.authentication-modal form .number-input {
  padding: 26px 145px 26px 25px;
}
.authentication-modal form .number-span {
  color: var(--color-blue);
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 20px;
  line-height: 24px;
}
.authentication-modal form .qr-code {
  text-align: center;
  margin: 0 auto 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
}

/*======================================
  Withdrawal-notification Style End
======================================*/



/*======================================
  Google Authenticator Style Start
======================================*/

.login.google-auth .title {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 11px;
  color: var(--color-white);
  max-width: 780px;
  margin-inline: auto;
}
.all-auths {
  max-width: 967px;
  margin-inline: auto;
}
.inner-auths {
  margin: 0 0 50px;
  color: var(--color-white);
}
.inner-auths .inner-auth-tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-transform: uppercase;
  padding: 26px 25px;
  gap: 0.625rem;
  margin: auto 0;
  border-radius: 9px;
  border: 1px solid var(--color-white);
  margin: 0 0  10px;
}
.inner-auths.active .inner-auth-tab {
  border: 1.5px solid var(--color-blue);
}
.inner-auths .inner-auth-tab h2 {
  font-size: inherit;
  width: calc(100% - 60px);
}
.inner-auths .inner-auth-tab .trash-icon {
  width: 30px;
  text-align: center;
  margin-left: 30px;
}
.inner-auths .inner-auth-tab .trash-icon:hover {
  color: #FF0000;
}
.inner-auths h3 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  margin: 0;
}
.btn:disabled{
  cursor: not-allowed;
}
.no-auths h2 {
  text-align: center;
  color: #fff;
  margin: 0 0 50px;
  font-size: 20px;
  line-height: 1.5;
}
.add-authenticator.withdrawal {
  min-height: 100vh;
}
.add-authenticator.withdrawal .heading {
  font-size: 50px;
}

/*======================================
  Google Authenticator Style End
======================================*/

/*======================================
  Home Page Style End
======================================*/
/*!============================================================================*/
/*======================================
  Media Quries Started
======================================*/
@media screen and (max-width: 1599px) {
  .withdrawal-notification .heading {
    font-size: 48px;
  }
  .authentication-modal .modal-body p {
    margin: 0 0 30px;
  }
  .modal .modal-body-end {
    padding: 25px 0 0;
  }
  .modal .modal-footer .modal-footer-inner {
    font-size: 16px;
  }
  .modal .modal-body-end h4 {
    font-size: 22px;
  }
  .modal .modal-body-end h4 strong {
    font-size: 22px;
  }
  .modal-content .modal-header .btn-close {
    width: 18px;
    height: 18px;
  }
  .authentication-modal .modal-content .modal-header {
    padding: 7px;
  }
  .btn {
    font-size: 15px;
  }
  .modal .modal-body-end .input-group {
    margin-bottom: 24px;
  }
  .authentication-modal .modal-body h1 {
    font-size: 33px;
  }
  .authentication-modal form .qr-code {
    max-width: 165px;
    max-height: 165px;
    margin: 0 auto 15px;
  }
  .authentication-modal .modal-footer button {
    font-size: 16px;
  }
  .modal .modal-footer {
    margin-top: 10px;
  }
  .authentication-modal form .form-control {
    margin-bottom: 22px;
  }
  .authentication-modal .modal-dialog {
    max-width: 600px;
  }
}
@media screen and (max-width: 1399px){
  .withdrawal-notification .heading {
    font-size: 34px;
  }
  .withdrawal form .custom-select-header {
    padding: 14px 25px;
  }
  .withdrawal form span {
    font-size: 14px;
    line-height: 20px;
  }
  .withdrawal form .form-control {
    padding: 14px 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .withdrawal form .number-input {
    padding: 14px 106px 14px 25px;
  }
  .checkbox-container {
    font-size: 16px;
  }
  .withdrawal .checkboxes {
    margin-bottom: 60px;
  }
  .withdrawal-notification .modals .modal-button {
    font-size: 20px;
    padding: 20px;
    margin: 0 auto 30px;
  }
  .authentication-modal .modal-body h1 {
    font-size: 30px;
  }
  .authentication-modal .modal-footer button {
    padding: 14px 29px;
  }
  .authentication-modal form .form-control {
    padding: 14px 25px;
    font-size: 14px;
    line-height: 18px;
  }
  .authentication-modal form .number-span {
    font-size: 14px;
    line-height: 18px;
  }
  .authentication-modal form .number-input {
    padding: 14px 106px 14px 25px;
  }
  .withdrawal-notification .number-tag {
    font-size: 40px;
  }
  .withdrawal-notification .withdraw-process .circle-tick {
    width: 110px;
    height: 110px;
    margin: 0 auto 45px;
  }
  .withdrawal-notification .withdraw-process {
    font-size: 35px;
    padding-top: 20px;
  }
  .authentication-modal .modal-dialog {
    max-width: 540px;
  }
  .modal .modal-footer .modal-footer-inner strong {
    font-size: 14px;
  }
  .authentication-modal .modal-content {
    padding: 20px 20px;
  }
  .withdrawal-notification p {
    max-width: 650px;
    font-size: 15px;
    margin-bottom: 30px;
  }
  .withdrawal-notification .verification-data .answer-text {
    font-size: 18px;
  }
  .withdrawal-notification .verification-data .small-text {
    font-size: 16px;
  }
  .login .title {
    font-size: 28px;
  }
  .login .description {
    font-size: 16px;
    margin-bottom: 2.0em;
    max-width: 655px;
  }
  .login.google-auth .title {
    font-size: 42px;
    max-width: 620px;
  }
  .inner-auths .inner-auth-tab {
    padding: 18px 25px;
  }
  .authentication-modal .modal-content .modal-body {
    padding: 5px 0 0;
  }
  .modal .modal-body-end {
    padding: 15px 0;
  }
  .modal .modal-body-end h4 {
    font-size: 18px;
  }
  .modal .modal-body-end h4 strong {
    font-size: 18px;
  }
  .modal .modal-body-end .input-group {
    margin-bottom: 18px;
  }
  .modal .modal-footer .modal-footer-inner {
    padding: 10px;
    font-size: 13px;
  }
  .form-control {
    font-size: 14px;
    padding: 14px 25px;
  }
  .modal .modal-footer {
    margin-top: 10px;
  }
  .btn {
    font-size: 14px;
  }
  .modal .modal-body h3 {
    font-size: 28px;
  }
  .authentication-modal .modal-body p {
    font-size: 14px;
    margin: 0 0 18px;
  }
  .authentication-modal .modal-footer button {
    font-size: 14px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 14px;
  }
  .custom-select-header {
    font-size: 14px;
    padding: 14px 25px;
  }
}
@media screen and (max-width: 1199px){

}
@media screen and (max-width: 991px) {
  .navbar {
    z-index: 99;
    position: relative;
    padding: 1rem 0;
  }
  .navbar .dashboard-dropdowns {
    justify-content: center;
  }
  .navbar .navbar-collapse {
    padding: 20px 20px 0px;
  }
  .navbar .navbar-nav {
    gap: 0px;
  }
  .navbar .login-button:first-of-type {
    margin-left: 0px;
  }
  .navbar .navbar-collapse li.nav-item {
    margin-bottom: 20px;
  }
 .navbar .navbar-dropdowns {
    margin-left: 0rem;
  }
  .home-hero,
  .dashboard-hero {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home-hero .title {
    font-size: 50px;
  }
  .benefits .title,
  .analytics .title,
  .portfolio .heading {
    font-size: 30px;
  }
  .dashboard-buttons .btn-solid {
    width: auto;
  }
  .analytics {
    padding-top: 45px;
  }
  .analytics .powered-by .boxes {
    gap: 30px;
    flex-wrap: wrap;
  }
  .analytics .powered-by .box {
    max-width: 240px;
    height: 120px;
  }
  .analytics .powered-by {
    margin-bottom: 90px;
  }
  .analytics .lg-boxes {
    gap: 40px;
    flex-wrap: wrap;
  }
  .analytics .analytics-inner {
    padding-bottom: 45px;
  }
  .investment {
    padding: 45px 0;
  }
  .investment-box {
    padding: 30px;
  }
  .investment-box .investment-box-inner,
  .benefits-boxes .benefits-box .box-desc .title {
    font-size: 20px;
  }
  .benefits {
    padding-top: 45px;
    padding-bottom: 100px;
  }
  .benefits .description,
  .portfolio .description {
    font-size: 16px;
    margin-bottom: 2em;
  }
  .benefits-boxes .benefits-box:nth-child(2) .box-title::after,
  .benefits-boxes .benefits-box:nth-child(2) .box-title::before {
    max-width: 100px;
  }
  .benefits-boxes .benefits-box .box-desc {
    margin-top: 30px;
  }
  .modal .modal-footer .modal-footer-inner,
  .modal .modal-footer .modal-footer-inner strong {
    font-size: 16px;
  }
  .portfolio {
    padding-top: 45px;
  }
  .dashboard-hero .title {
    font-size: 40px;
  }
  .dashboard-hero .title .icon {
    width: 40px;
    height: 47px;
  }
  .withdrawal {
    padding-bottom: 100px;
    padding-top: 50px;
  }
  .login {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .withdrawal .heading {
    font-size: 40px;
  }
  .withdrawal form .withdraw-data span:nth-child(02) {
    font-size: 26px;
    line-height: 30px;
  }
  .withdrawal-notification {
    padding-bottom: 100px;
    padding-top: 50px;
  }
  .withdrawal-notification .heading {
    font-size: 36px;
  }
  .withdrawal-notification p {
    font-size: 18px;
  }
  .withdrawal-notification .verification-data .answer-text {
    font-size: 18px;
  }
  .withdrawal-notification .verification-data .small-text {
    font-size: 18px;
  }
  .withdrawal-notification .verification-data {
    width: 50%;
  }
  .authentication-modal .modal-dialog {
    max-width: 500px;
    margin-inline: auto;
  }
  .modal .modal-footer {
    margin-top: 30px;
  }
  .modal-content .modal-header .btn-close {
    width: 18px;
    height: 18px;
  }
  .authentication-modal .modal-content .modal-header {
    padding: 7px;
  } 
  .login .description {
    font-size: 14px;
    max-width: 560px;
  }
  .login .title {
    font-size: 22px;
    margin-top: 20px;
  }
  .login.google-auth .title {
    font-size: 32px;
    max-width: 480px;
  }
  .inner-auths .inner-auth-tab {
    font-size: 18px;
    margin: 0 0 10px;
  }
  .inner-auths h3 {
    font-size: 16px;
  }
  .no-auths h2 {
    font-size: 18px;
  }
  .add-authenticator.withdrawal .heading {
    font-size: 40px;
  }
  .modal .modal-footer {
    margin-top: 12px;
  }
  .modal .modal-footer .modal-footer-inner, .modal .modal-footer .modal-footer-inner strong {
    font-size: 14px;
  }
  .modal .modal-footer .modal-footer-inner {
    padding: 10px 5px;
  }
  .modal .modal-body h3 {
    font-size: 24px;  
  }
}

@media screen and (max-width: 767px) {
  .analytics .lg-box {
    padding: 20px 20px;
  }
  .toast-container {
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    font-size: 0 !important;
    display: none !important;
    position: absolute !important;
  }
  .login::after,
  .login::before,
  .benefits::after,
  .home-hero::after,
  .home-hero::before,
  .portfolio::before,
  .dashboard-hero::after,
  .dashboard-hero::before,
  .analytics-inner::before,
  .modal .modal-dialog .modal-content::after,
  .modal .modal-dialog .modal-content::before {
    width: 300px;
    height: 300px;
  }
  .modal-content .modal-header {
    padding: 20px 25px 10px;
  }
  .modal-content .modal-header .btn-close {
    width: 20px;
    height: 20px;
  }
  .star:nth-child(2) {
    top: 80%;
    left: 2%;
  }
  .star:last-of-type {
    right: 2%;
  }
  .dashboard-buttons {
    gap: 20px;
  }
  .home-hero .title {
    font-size: 40px;
  }
  .benefits .title,
  .analytics .title,
  .portfolio .heading,
  .modal .modal-body h3 {
    font-size: 25px;
    max-width: 100%;
  }
  .dashboard-buttons .btn-solid {
    width: auto;
  }
  .analytics .powered-by .boxes {
    gap: 30px;
    flex-wrap: wrap;
  }
  .analytics .powered-by .box {
    max-width: 240px;
    height: 120px;
  }
  .analytics .powered-by {
    margin-bottom: 90px;
  }
  .analytics .lg-boxes {
    gap: 40px;
    flex-wrap: wrap;
  }
  .analytics .analytics-inner {
    padding-bottom: 45px;
  }
  .investment {
    padding: 45px 0;
  }
  .investment-box {
    padding: 30px;
  }
  .investment-box .investment-box-inner,
  .benefits-boxes .benefits-box .box-desc .title {
    font-size: 20px;
  }
  .benefits {
    padding-top: 45px;
    padding-bottom: 100px;
  }
  .benefits .description,
  .portfolio .description {
    font-size: 16px;
    margin-bottom: 2em;
  }
  .benefits-boxes {
    gap: 30px;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .benefits-boxes .benefits-box {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .benefits-boxes .benefits-box:nth-child(2) .box-title::after,
  .benefits-boxes .benefits-box:nth-child(2) .box-title::before {
    content: none;
  }
  .benefits-boxes .benefits-box .box-desc {
    margin-top: 30px;
  }
  .dashboard-buttons {
    flex-wrap: wrap;
  }
  .portfolio .trading-started .trading-started-inner {
    padding: 20px;
  }
  .portfolio .portfolio-balance > .d-flex {
    gap: 30px;
    flex-wrap: wrap;
  }
  .dashboard-buttons .btn .btn-title {
    font-size: 14px;
  }
  .dashboard-buttons .btn .btn-content {
    font-size: 16px;
  }
  .modal .modal-body,
  .portfolio .address-group .input-group .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal .modal-footer {
    padding: 0 10px 10px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 12.5px;
    border-radius: 9px !important;
    padding: 10px 12px;
  }
  .authentication-modal form .form-control {
    padding: 10px 12px;
  }
  .modal .modal-body-end .input-group .btn {
    display: none;
  }
  .portfolio .address-group .input-group .form-control {
    font-size: 12px;
    padding-right: 0;
  }
  .dashboard-hero .title {
    font-size: 30px;
  }
  .dashboard-hero .title .icon {
    width: 30px;
    height: 37px;
  }
  .user-dropdown {
    margin-left: 20px;
  }
  .user-dropdown .user-data {
    margin-right: 12px;
  }
  .user-dropdown .user-img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
  .navbar .navbar-brand {
    margin-right: 15px;
  }
  .withdrawal .heading {
    font-size: 30px;
    margin-bottom: 35px;
  }
  .checkbox-container {
    font-size: 14px;
  }
  .notifications-dropdown{
    position: static;
  }
  .notifications-dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
    min-width: auto;
    transform: none !important;
  }
  .withdrawal-notification .verification-data .answer-text {
    word-break: break-word;
  }
  .withdrawal-notification .verification-data .small-text {
    white-space: nowrap;
  }
  .withdrawal form .withdraw-data span:nth-child(02) {
    font-size: 22px;
    line-height: 26px;
  }
  .withdrawal-notification .heading {
    font-size: 26px;
  }
  .withdrawal-notification p {
    font-size: 16px;
  }
  .withdrawal-notification .verification-data .answer-text {
    font-size: 16px;
  }
  .withdrawal-notification .verification-data .small-text {
    font-size: 16px;
  }
  .withdrawal-notification .verification-content {
    margin: 0 0 40px;
  }
  .withdrawal-notification .number-tag {
    font-size: 32px;
    margin: 0px 0 40px;
  }
  .withdrawal-notification .modals .modal-button {
    margin: 0 auto 25px;
  }
  .withdrawal-notification .modals .modal-button:last-of-type {
    margin: 0 auto 0px;
  }
  .authentication-modal .modal-body h1 {
    font-size: 25px;
  }
  .authentication-modal .modal-body p {
    font-size: 14px;
  }
  .withdrawal form .custom-select-header {
    padding: 14px 25px;
  }
  .withdrawal form .form-control {
    padding: 14px 25px;
  }
  .authentication-modal .modal-footer button {
    padding: 10px 29px;
    font-size: 16px;
  }
  .authentication-modal form .number-span {
    font-size: 14px;
    line-height: 18px;
  }
  .authentication-modal form .number-input {
    padding: 14px 110px 14px 25px;
  }
  .withdrawal form .number-input {
    padding: 14px 110px 14px 25px;
  }
  .withdrawal-notification .heading {
    max-width: 410px;
  }
  .withdrawal-notification .verification-data {
    width: 100%;
    margin: 0 0 20px;
  }
  .withdrawal-notification .withdraw-process {
    font-size: 28px;
    padding-top: 10px;
  }
  .withdrawal-notification .withdraw-process .circle-tick {
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
  }
  .withdrawal form .custom-select-container {
    margin-bottom: 30px;
  }
  .inner-auths {
    margin: 0 0 30px;
  }
  .inner-auths .inner-auth-tab {
    padding: 14px 25px;
    font-size: 16px;
  }
  .inner-auths h3 {
    font-size: 14px;
  }
  .no-auths h2 {
    font-size: 16px;
  }
  .add-authenticator.withdrawal .heading {
    font-size: 30px;
  }
  .modal .modal-footer .modal-footer-inner, .modal .modal-footer .modal-footer-inner strong {
    font-size: 12px;
  }
  .custom-select-header {
    font-size: 13px;
    padding: 10px 12px;
  }
  .custom-select-arrow svg {
    width: 14px;
    height: 14px;
  }
}

@media screen and (max-width: 575px) {
  .navbar .navbar-brand {
    margin-right: 20px;
  }
  .custom-select-header {
    font-size: 12px;
  }
  .withdrawal .checkboxes {
    margin-bottom: 60px;
  }  
  .custom-select-option {
    font-size: 13px;
  }
  .authentication-modal .modal-body h1 {
    font-size: 20px;  
  }
  .authentication-modal .modal-content {
    padding: 20px 20px;
  }
  .checkbox-container {
    font-size: 13px;
  }
  .withdrawal .heading {
    font-size: 20px;
  }
  .withdrawal form span {
    font-size: 13px;
    line-height: 17px;
  }
  .withdrawal form .form-control {
    font-size: 13px;
    line-height: 17px;
    padding: 14px 12px;
  }
  .withdrawal form .withdraw-data span:nth-child(02) {
    font-size: 16px;
    line-height: 20px;
  }
  .withdrawal-notification .heading {
    font-size: 20px;
  }
  .withdrawal-notification p {
    font-size: 13px;
  }
  .withdrawal-notification .verification-data .answer-text {
    font-size: 13px;
  }
  .withdrawal-notification .verification-data .small-text {
    font-size: 13px;
  }
  .withdrawal form .custom-select-header {
    padding: 14px 12px;
  }
  .authentication-modal form .form-control {
    font-size: 13px;
    line-height: 17px;
  }
  .withdrawal-notification .modals .modal-button {
    font-size: 13px;
  }
  .user-dropdown .user-data {
    margin-right: 0px;
    display: none;
  }
  .user-dropdown .user-img {
    margin-right: 0px;
  }
  .user-dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar .navbar-collapse {
    padding: 20px 5px 0px;
  }
  .navbar .login-button {
    margin-left: 12px;
  }
  .login .description {
    max-width: 80%;
  }
  .inner-auths .inner-auth-tab .trash-icon {
    width: 20px;
    margin-left: 15px;
  }
  .inner-auths .inner-auth-tab h2 {
    width: calc(100% - 35px);
  }
  .authentication-modal form .qr-code {
    margin: 0 auto 15px;
    padding: 12px;
    max-width: 150px;
    max-height: 150px;
  }
  .authentication-modal form .form-control {
    margin-bottom: 15px;
  }
  .modal .modal-footer {
    margin-top: 15px;
  }
  .authentication-modal .modal-dialog {
    max-width: 95%;
  }
  .login::after, 
  .login::before, 
  .benefits::after, 
  .withdrawal::before, 
  .withdrawal::after, 
  .withdrawal-notification::before, 
  .withdrawal-notification::after, 
  .home-hero::after, 
  .home-hero::before, 
  .portfolio::before, 
  .dashboard-hero::after, 
  .dashboard-hero::before, 
  .analytics-inner::before, 
  .modal .modal-dialog .modal-content::after, 
  .modal .modal-dialog .modal-content::before {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 479px) {
  .navbar .navbar-brand {
    margin-right: 5px;
  }
  .withdrawal .withdraw-amount {
    flex-direction: column;
  }
  .withdrawal .withdraw-amount .withdraw-data {
    width: 100%;
    margin: 0 0 20px;
  }
  .withdrawal-notification .verification-data {
    flex-direction: column;
    text-align: center;
  }
  .withdrawal-notification .withdraw-process .circle-tick {
    width: 65px;
    height: 65px;
    margin: 0 auto 20px;
  }
  .withdrawal-notification .withdraw-process {
    font-size: 16px;
  }
  .navbar .navbar-toggler {
    margin-left: 12px !important;
    padding: 3px 7px;
    font-size: 16px;
  }
  .user-dropdown .dropdown-menu {
    transform: translateX(-60%) !important;
    -webkit-transform: translateX(-60%) !important;
    -ms-transform: translateX(-60%) !important;
    -moz-transform: translateX(-60%) !important;
    -o-transform: translateX(-60%) !important;
  }
  .user-dropdown {
    margin-left: 10px;
  }
  .form-control {
    padding: 15px 7px;
  }
  .modal .modal-body, .portfolio .address-group .input-group .btn {
    padding-left: 6px;
    padding-right: 6px;
  }
  .portfolio .address-group .input-group .form-control {
    font-size: 12px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 11px;
  }
  .login .description {
    max-width: 95%;
    font-size: 12px;
  }
  .login .title {
    font-size: 18px;
    margin-top: 16px;
  }
  .login.google-auth .title {
    font-size: 23px;
    max-width: 100%;
  }
  .inner-auths .inner-auth-tab {
    padding: 14px 12px;
    font-size: 13px;
  }
  .inner-auths h3 {
    font-size: 12px;
  }
  .no-auths h2 {
    font-size: 13px;
  }
  .add-authenticator.withdrawal .heading {
    font-size: 25px;
  }
  .benefits .title, .analytics .title, .portfolio .heading, .modal .modal-body h3 {
    font-size: 19px;
  }
  .authentication-modal .modal-body p {
    font-size: 12px;
  }
  .modal .modal-footer .modal-footer-inner, .modal .modal-footer .modal-footer-inner strong {
    font-size: 11px;
  }
  .modal-content .modal-header .btn-close {
    width: 15px;
    height: 15px;
  }
  .modal .modal-footer .modal-footer-inner {
    padding: 10px 3px;
  }
  .modal .modal-body-end label {
    font-size: 13px;
    line-height: 17px;
  }
  .modal .modal-body-end h4 {
    font-size: 14px;
  }
  .modal .modal-body-end h4 strong {
    font-size: 14px;
  }
}

@media screen and (max-width: 366px) {
  .navbar .navbar-brand {
    max-width: 195px;
  }
  .portfolio .address-group .input-group .form-control {
    font-size: 11px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 10px;
  }
  .authentication-modal .modal-footer button {
    font-size: 12px;
  }
  .authentication-modal form .qr-code {
    max-width: 100px;
    max-height: 100px;
  }
}

@media screen and (max-width: 339px) {
  .navbar .navbar-brand {
    max-width: 175px;
  }
  .portfolio .address-group .input-group .form-control {
    font-size: 10.5px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 9.5px;
  }
}

@media screen and (max-width: 319px) {
  .navbar .navbar-brand {
    max-width: 145px;
  }
  .user-dropdown .user-img {
    width: 25px;
    height: 25px;
  }
  .notifications-dropdown #noti-button {
    width: 18px;
    height: 18px;
  }
  .input-group-text.btn img {
    width: 14px;
  }
  .portfolio .address-group .input-group .form-control {
    font-size: 10px;
  }
  .modal .modal-body-end .input-group .form-control {
    font-size: 9px;
  }
  .login.google-auth .title {
    font-size: 20px;
  }
  .add-authenticator.withdrawal .heading {
    font-size: 22px;
  }
  .benefits .title, .analytics .title, .portfolio .heading, .modal .modal-body h3 {
    font-size: 16px;
  }
}

/*======================================
  Media Quries End
======================================*/
